import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { Visitor } from '../../models/Visits/Visitor';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  createVisitor,
  deleteVisitor,
  deleteVisitors,
  pacsVisitorDeleted,
  pacsVisitorNotDeleted,
  pacsVisitorNotUpdated,
  pacsVisitorUpdated,
  updateVisitor,
  visitorCreated,
  visitorDeleted,
  visitorNotCreated,
  visitorNotDeleted,
  visitorNotUpdated,
  visitorsDeleted,
  visitorsNotDeleted,
  visitorUpdated,
} from './Visitors.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createVisitorSaga() {
  yield takeEvery(createVisitor.type, createVisitorRequest);
}

function* visitorCreatedSaga() {
  yield takeLatest(visitorCreated.type, createVisitorResponse);
}

function* visitorNotCreatedSaga() {
  yield takeLatest(visitorNotCreated.type, createVisitorError);
}

// Request
function* createVisitorRequest(action: PayloadAction<Visitor>) {
  try {
    const { payload: data } = action;

    yield apiService.execute({
      url: 'Visitors',
      method: ApiRequestType.POST,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitorNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createVisitorResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('visitors.notifications.create');
  if (!action.payload.msg.identityUserId) {
    action.payload.history.push(`/Visitors/${action.payload.msg.entityId}`);
  }
}

// Error
function createVisitorError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visitors.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateVisitorSaga() {
  yield takeEvery(updateVisitor.type, updateVisitorRequest);
}

function* visitorUpdatedSaga() {
  yield takeLatest(visitorUpdated.type, updateVisitorResponse);
}

function* visitorNotUpdatedSaga() {
  yield takeLatest(visitorNotUpdated.type, updateVisitorError);
}

function* pacsVisitorUpdatedSaga() {
  yield takeLatest(pacsVisitorUpdated.type, pacsUpdateVisitorResponse);
}

function* pacsVisitorNotUpdatedSaga() {
  yield takeLatest(pacsVisitorNotUpdated.type, pacsVisitorNotUpdatedError);
}

// Request
function* updateVisitorRequest(action: PayloadAction<Visitor>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Visitors/${data.Id}`,
      method: ApiRequestType.PUT,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitorNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateVisitorResponse() {
  notificationService.showSuccess('visitors.notifications.update');
}

function pacsUpdateVisitorResponse() {
  notificationService.showSuccess('visitors.notifications.updatePacs');
}

// Error
function updateVisitorError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visitors.notifications.updateFailed', action?.payload?.msg.message);
}

function pacsVisitorNotUpdatedError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visitors.notifications.updatePacsFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteVisitorSaga() {
  yield takeEvery(deleteVisitor.type, deleteVisitorRequest);
}

function* visitorDeletedSaga() {
  yield takeLatest(visitorDeleted.type, deleteVisitorResponse);
}

function* visitorNotDeletedSaga() {
  yield takeLatest(visitorNotDeleted.type, deleteVisitorError);
}

function* pacsVisitorDeletedSaga() {
  yield takeLatest(pacsVisitorDeleted.type, pacsDeleteVisitorResponse);
}

function* pacsVisitorNotDeletedSaga() {
  yield takeLatest(pacsVisitorNotDeleted.type, pacsVisitorNotDeletedError);
}

// Request
function* deleteVisitorRequest(action: PayloadAction<Visitor>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Visitors/${data.Id}`,
      method: ApiRequestType.DELETE,
      data,
    });
  } catch ({ message }) {
    yield put({ type: visitorNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteVisitorResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('visitors.notifications.delete');
  if (action.payload.msg.identityUserId) {
    action.payload.history.push(`/Reservations/New_visitor`);
  } else {
    action.payload.history.push(`/Visitors`);
  }
}

function pacsDeleteVisitorResponse() {
  notificationService.showSuccess('visitors.notifications.deletePacs');
}

// Error
function deleteVisitorError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visitors.notifications.deleteFailed', action?.payload?.msg.message);
}

function pacsVisitorNotDeletedError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visitors.notifications.deletePacsFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteVisitorsSaga() {
  yield takeEvery(deleteVisitors.type, deleteVisitorsRequest);
}

function* visitorsDeletedSaga() {
  yield takeLatest(visitorsDeleted.type, deleteVisitorsResponse);
}

function* visitorsNotDeletedSaga() {
  yield takeLatest(visitorsNotDeleted.type, deleteVisitorsError);
}

// Request
function* deleteVisitorsRequest(action: PayloadAction<Array<Visitor>>) {
  try {
    const { payload: data } = action;
    yield apiService.execute({
      url: `Visitors`,
      method: ApiRequestType.DELETE,
      data: { ids: uniq(data.map((item) => item.Id)) },
    });
  } catch ({ message }) {
    yield put({ type: visitorsNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteVisitorsResponse() {
  notificationService.showSuccess('visitors.notifications.deleteAll');
}

// Error
function deleteVisitorsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('visitors.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createVisitorSaga(),
    visitorCreatedSaga(),
    visitorNotCreatedSaga(),
    // Update
    updateVisitorSaga(),
    visitorUpdatedSaga(),
    visitorNotUpdatedSaga(),
    pacsVisitorUpdatedSaga(),
    pacsVisitorNotUpdatedSaga(),
    // Delete
    deleteVisitorSaga(),
    visitorDeletedSaga(),
    visitorNotDeletedSaga(),
    pacsVisitorDeletedSaga(),
    pacsVisitorNotDeletedSaga(),
    // Delete All
    deleteVisitorsSaga(),
    visitorsDeletedSaga(),
    visitorsNotDeletedSaga(),
  ]);
}
