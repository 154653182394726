import React, { PropsWithChildren } from 'react';
import { Spin } from 'antd';
import { useIntl } from 'react-intl';

import logo from '../../../assets/images/loader.svg';

interface SpinnerProps {
  spinning?: boolean;
  message?: string;
}

export const Spinner = ({ children, spinning = undefined, message = undefined }: PropsWithChildren<SpinnerProps>) => {
  const intl = useIntl();
  return (
    <Spin
      size="large"
      spinning={spinning}
      tip={message ?? intl.formatMessage({ id: 'app.pleaseWait', defaultMessage: 'Please wait' })}
      indicator={<img src={logo} alt="loading..." />}
    >
      {children}
    </Spin>
  );
};
