import React from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from 'rc-picker/lib/interface';
import 'moment/locale/en-gb';
import locale from 'antd/es/locale/en_GB';
import moment, { Moment } from 'moment';

// Hooks
import { dateTimeStringFormat, MomentInput } from '../../../constants/Utils/DateTime';

// Components
const { RangePicker } = DatePicker;

// Styled
const StyledRangePicker = styled(RangePicker)`
  width: 100%;

  .ant-picker-ok {
    margin-bottom: 0;
  }
`;

// Locale Config
moment.updateLocale('en-gb', { week: { dow: 1 } });

// Props
export interface DateTimePickerProps {
  initialValues?: RangeValue<MomentInput>;
  onValueChange: (values: RangeValue<Moment>) => void;
}

// Component
export const DateTimePicker: React.FC<DateTimePickerProps> = ({ initialValues = undefined, onValueChange }) => {
  // Hooks
  const intl = useIntl();

  // Variables
  const [from, to] = initialValues ?? [];
  // Event Handlers
  const onChange = (values: RangeValue<Moment>) => {
    onValueChange(values);
  };

  const disabledDate = (current: any) => {
    // Return true if the selected date is in the past
    return current && current < moment().startOf('day');
  };

  // Render
  return (
    <ConfigProvider locale={locale}>
      <StyledRangePicker
        defaultValue={[moment(from), moment(to)]}
        ranges={{
          [intl.formatMessage({ id: 'app.today' })]: [moment().hours(9).minutes(0), moment().hours(17).minutes(0)],
          [intl.formatMessage({ id: 'app.tomorrow' })]: [
            moment().add(1, 'day').hours(9).minutes(0),
            moment().add(1, 'day').hours(17).minutes(0),
          ],
          [intl.formatMessage({ id: 'app.thisWeek' })]: [
            moment().hours(9).minutes(0),
            moment().isoWeekday(7).hours(17).minutes(0),
          ],
          [intl.formatMessage({ id: 'app.thisMonth' })]: [
            moment().hours(9).minutes(0),
            moment().endOf('month').hours(17).minutes(0),
          ],
        }}
        onChange={onChange}
        showTime={{ format: 'HH:mm' }}
        disabledDate={disabledDate}
        format={dateTimeStringFormat}
      />
    </ConfigProvider>
  );
};
