import React from 'react';
import styled from 'styled-components';

// Props
import { TableHeadProps } from '../../../../hooks/Table/useTableProps';

// Components
import { Text } from '../../Base';
import { TableRow } from './TableRow';
import { TableHeadColumn, TableHeadColumnProps } from './TableHeadColumn';

// Styled
const TableHeadWrapper = styled.thead`
  z-index: 10;
  position: sticky;
  top: 0;

  tr {
    top: -1px;
    display: flex;
    position: sticky;
    background: #f7fafc;
    z-index: 1;
  }
`;

// Render
export const TableHead = <T extends object>({
  columns,
  getHiddenColumnsProps,
}: React.PropsWithChildren<TableHeadProps<T>>) => (
  <TableHeadWrapper>
    <TableRow noHover>
      {columns.map(
        (column) =>
          (getHiddenColumnsProps().showAllColumns || column.isVisible) && (
            <TableHeadColumn<React.FC<TableHeadColumnProps<T>>>
              {...column.getHeaderProps(
                getHiddenColumnsProps().showAllColumns ? undefined : column.getSortByToggleProps({ title: undefined })
              )}
              {...getHiddenColumnsProps()}
              column={column}
              key={column.id}
            >
              <Text>{column.render('Header')}</Text>
            </TableHeadColumn>
          )
      )}
    </TableRow>
  </TableHeadWrapper>
);
