import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { useVisitDrawer } from './useVisitDrawer';
import { useAppSelector } from '../App/useRedux';

export const useReservationsDrawer = <T extends object>() => {
  // Drawer State
  const [open, setOpen] = useState(false);

  const updating = useAppSelector(({ reservations }) => reservations?.updating ?? false);
  const error = useAppSelector(({ reservations }) => reservations?.error ?? false);

  // Redux State
  const prevUpdating = usePrevious(updating);

  // Form Hooks
  const { getFormDrawerProps: getCreateVisitFormDrawerProps, setVisit } = useVisitDrawer<T>({ isEditing: false });

  const getFormDrawerProps = useCallback(
    () => ({
      open,
      setOpen,
      setVisit,
      getCreateVisitFormDrawerProps,
    }),
    [getCreateVisitFormDrawerProps, open, setVisit]
  );

  // Effects
  useEffect(() => {
    // Close Drawer on successful update
    if (prevUpdating === true && !updating && !error) {
      setOpen(false);
    }
  }, [prevUpdating, updating, error]);

  // Return Hook
  return useMemo(
    () => ({
      getFormDrawerProps,
    }),
    [getFormDrawerProps]
  );
};
