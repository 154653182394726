/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { Card, Col, Empty, Row, Skeleton } from 'antd';
import styled from 'styled-components';

// Models
import { ForwardFilled, ThunderboltFilled, TruckFilled } from '@ant-design/icons';
import moment from 'moment';

// Components
import { useIntl } from 'react-intl';
import { Translated } from '../../UI/Core';
import { Visit } from '../../../models/Visits/Visit';
import { VisitType } from '../../../models/enums/VisitType';
import { Flex } from '../../UI/Base';
import { useQueryData } from '../../../hooks/App/useQueryData';
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Styled
const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 18rem;
  height: auto;
  aspect-ratio: 2 / 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;

  & .ant-card-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

// Props
interface ReservationsDrawerProps {
  setVisit: React.Dispatch<React.SetStateAction<Visit | undefined>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Component
export const ReservationsForm = ({ setVisit, setOpen }: ReservationsDrawerProps) => {
  // Intl
  const intl = useIntl();

  const { data: reservationSettings, isPending } = useQueryData(ApiEndpoints.reservations.settings, [
    'Reservations',
    'Settings',
  ]);

  const { data: visitor } = useQueryData(ApiEndpoints.reservations.detail, ['Reservations', 'Details']);

  // Render Methods
  const renderLoading = () => {
    return (
      <Row>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <Skeleton active />
        </Col>
      </Row>
    );
  };

  const renderEmpty = () => {
    return (
      <Row>
        <Col xs={24}>
          <Empty
            description={
              <span>
                <Translated id="reservations.form.empty" />
              </span>
            }
          />
        </Col>
      </Row>
    );
  };

  const RenderForm = useCallback(() => {
    const validFrom = moment().isAfter(moment().hour(17).minutes(0).seconds(0).local())
      ? moment()
      : moment().hour(9).minutes(0).seconds(0).local();

    const validTo = moment().isAfter(moment().hour(17).minutes(0).seconds(0).local())
      ? moment().add(4, 'hours')
      : moment().hour(17).minutes(0).seconds(0).local();

    return (
      <Flex style={{ marginTop: 25 }} direction="column" alignContent="center" justifyContent="space-evenly" flexWrap>
        {!reservationSettings?.RegularParkingReservationsEnabled &&
          !reservationSettings?.ElectricParkingReservationsEnabled &&
          !reservationSettings?.FastElectricParkingReservationsEnabled && (
            <Empty
              description={
                <span>
                  <Translated id="reservations.form.empty" />
                </span>
              }
            />
          )}
        {reservationSettings?.RegularParkingReservationsEnabled && (
          <StyledCard
            hoverable
            key="Regular"
            onClick={() => {
              setVisit({
                Description: `${intl.formatMessage({
                  id: 'reservations.regularParking',
                })} - ${visitor?.Identifiers?.at(0)?.Name ?? ''}`,
                Guests: [],
                Hosts: [],
                Id: '',
                Title: `${intl.formatMessage({
                  id: 'reservations.parkingReservation',
                })} - ${validFrom?.format('L')}`,
                ValidFrom: validFrom.format(),
                ValidTo: validTo.format(),
                VisitType: VisitType.Default,
              });
              setOpen(true);
            }}
          >
            <TruckFilled style={{ fontSize: '2rem' }} />
            <Translated id="reservations.regularParking" />
          </StyledCard>
        )}
        {reservationSettings?.ElectricParkingReservationsEnabled && (
          <StyledCard
            hoverable
            key="Electric"
            onClick={() => {
              setVisit({
                Description: `${intl.formatMessage({
                  id: 'reservations.electricCharging',
                })} - ${visitor?.Identifiers?.at(0)?.Name ?? ''}`,
                Guests: [],
                Hosts: [],
                Id: '',
                Title: `${intl.formatMessage({
                  id: 'reservations.parkingReservation',
                })} - ${validFrom?.format('L')}`,
                ValidFrom: validFrom.format(),
                ValidTo: validTo.format(),
                VisitType: VisitType.Default,
              });
              setOpen(true);
            }}
          >
            <ThunderboltFilled style={{ fontSize: '2rem' }} />
            <Translated id="reservations.electricCharging" />
          </StyledCard>
        )}
        {reservationSettings?.FastElectricParkingReservationsEnabled && (
          <StyledCard
            hoverable
            key="Fast"
            onClick={() => {
              setVisit({
                Description: `${intl.formatMessage({
                  id: 'reservations.fastCharging',
                })} - ${visitor?.Identifiers?.at(0)?.Name ?? ''}`,
                Guests: [],
                Hosts: [],
                Id: '',
                Title: `${intl.formatMessage({
                  id: 'reservations.parkingReservation',
                })} - ${validFrom?.format('L')}`,
                ValidFrom: validFrom.format(),
                ValidTo: validTo.format(),
                VisitType: VisitType.Default,
              });
              setOpen(true);
            }}
          >
            <ForwardFilled style={{ fontSize: '2rem' }} />
            <Translated id="reservations.fastCharging" />
          </StyledCard>
        )}
      </Flex>
    );
  }, [
    reservationSettings?.RegularParkingReservationsEnabled,
    reservationSettings?.ElectricParkingReservationsEnabled,
    reservationSettings?.FastElectricParkingReservationsEnabled,
    setVisit,
    intl,
    visitor?.Identifiers,
    setOpen,
  ]);

  if (isPending) return renderLoading();

  if (!reservationSettings) return renderEmpty();

  // Component Render
  return <RenderForm />;
};
