import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button, Card, Col, Empty, Input, Row, Tabs, Tag } from 'antd';
import { useParams } from 'react-router';
import { usePrevious } from 'react-use';
import { ButtonSkeleton } from '../UI/Skeleton/ButtonSkeleton';
import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { Action, ExecuteAction } from '../../models/Action';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { PersonType } from '../../models/enums/PersonType';
import { Spinner } from '../UI/Spinner/Spinner';
import { executeAction } from '../../store/Actions/Actions.redux';
import { Visitor } from '../../models/Visits/Visitor';
import { ActionType } from '../../models/enums/ActionType';
import { ActionStatus } from '../../models/enums/ActionStatus';

interface ActionsCardProps {
  actionsData: Action[];
  pendingAction: boolean;
  visitor: Visitor | null;
  fetchVisitor: (params: any) => Promise<void>;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
  width: 200px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledCard = styled(Card)`
  width: 320px;
  border-radius: 12px;
`;

const IconContainer = styled.div`
  font-size: 72px;
  color: #81c8de;
  margin-bottom: 16px;
`;

export const ActionsCard = ({ actionsData, pendingAction, visitor, fetchVisitor }: ActionsCardProps) => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(({ actions }) => actions?.error ?? false);
  const updating = useAppSelector(({ actions }) => actions?.updating ?? false);
  const prevUpdating = usePrevious(updating);
  const { id } = useParams();

  useEffect(() => {
    // Fetch after updating
    if (visitor && prevUpdating === true && updating === false && !error) {
      fetchVisitor({ id: visitor.Id });
    }
  }, [prevUpdating, updating, error, fetchVisitor, visitor]);

  const executeActionBtnClick = useCallback(
    (actionId: string) => {
      const data: ExecuteAction = {
        ActionId: actionId,
        PersonId: id,
        PersonType: PersonType.Visitor,
      };
      dispatch(executeAction(data));
    },
    [id, dispatch]
  );

  const getStatusDetails = useCallback((status: ActionStatus) => {
    switch (status) {
      case ActionStatus.DeviceError:
        return { text: 'Device Error', color: 'red' };
      case ActionStatus.GeneralError:
        return { text: 'General Error', color: 'orange' };
      case ActionStatus.Default:
        return { text: '', color: 'grey' };
      case ActionStatus.Active:
        return { text: 'Active', color: 'green' };
      case ActionStatus.UpdateRequired:
        return { text: 'Update Required', color: 'blue' };
      case ActionStatus.Expired:
        return { text: 'Expired', color: 'darkgrey' };
      default:
        return { text: 'Unknown Status', color: 'lightgrey' };
    }
  }, []);

  const tabItems = useMemo(
    () =>
      actionsData.map((action, index) => ({
        label: action.Name,
        key: index.toString(),
        children: (
          <div key={action.Id} className="row">
            <Input name="Id" hidden />
            <Wrapper>
              <StyledCard>
                <div className="justify-content-center flex">
                  <div className="gx-mb-0 gx-text-grey gx-fs-sm">
                    {pendingAction ? <ButtonSkeleton width={120} height={10} /> : ''}
                  </div>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <IconContainer>
                      {action.Type === ActionType.Encode ? (
                        <i className="fas fa-credit-card" />
                      ) : (
                        <i className="fas fa-door-closed" />
                      )}
                    </IconContainer>
                    <div>
                      {visitor?.ActionStatuses?.map(
                        (x) =>
                          x.ActionId === action.Id && (
                            <div key={x.ActionId}>
                              {(() => {
                                const { text, color } = getStatusDetails(x.Status);
                                if (text === '') return <div />;
                                return (
                                  <Tag color={color}>
                                    {text} {x.Message}
                                  </Tag>
                                );
                              })()}
                            </div>
                          )
                      )}
                    </div>
                    <StyledButton type="primary" onClick={() => executeActionBtnClick(action.Id)}>
                      <span className="gx-mb-0 gx-text-white gx-fs-sm">
                        {pendingAction ? <ButtonSkeleton width={120} height={10} /> : action.DisplayLabel}
                      </span>
                    </StyledButton>
                  </div>
                </div>
              </StyledCard>
            </Wrapper>
          </div>
        ),
      })),
    [actionsData, executeActionBtnClick, getStatusDetails, pendingAction, visitor?.ActionStatuses]
  );

  return (
    <Widget>
      {actionsData.length !== 0 && !!visitor ? (
        <Spinner spinning={updating}>
          <Tabs defaultActiveKey="0" items={tabItems} />
        </Spinner>
      ) : (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="actions.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      )}
    </Widget>
  );
};
