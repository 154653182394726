import React from 'react';
import { Empty, Skeleton, Tabs } from 'antd';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

// Models
import { CheckCircleOutlined } from '@ant-design/icons';
import { Visit } from '../../../../models/Visits/Visit';
import { GuestStatus } from '../../../../models/enums/GuestStatus';

// Constants
import { isInThePast } from '../../../../constants/Utils/DateTime';

// Redux
// Components
import { VisitsHistory } from './VisitsHistory';
import { VisitsTabContent } from './VisitsTabContent';
import { Widget } from '../../../UI/Widget/Widget';
import { Translated } from '../../../UI/Core';
import { AppColors, BaseColors } from '../../../../constants/Utils/Colors';
import { Flex } from '../../../UI/Base';
import { useQueryData } from '../../../../hooks/App/useQueryData';
import { ApiEndpoints } from '../../../../data/ApiEndpoints';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;

// Styled Component
const StyledTabs = styled(Tabs)`
  & .ant-tabs-tab:first-child {
    margin-left: 24px;
  }

  & .ant-tabs-nav {
    height: 58px;
    margin-bottom: 0;
  }

  & .ant-tabs-tab {
    & .ant-tabs-tab-btn {
      & .is-checked-in {
        color: ${() => AppColors.orange};
        font-weight: 600;
      }

      & .is-checked-out {
        color: ${() => BaseColors.darkGray};
      }
    }
  }
`;

// Props
interface VisitsTabsProps {
  showVisitDetails: (visit: Visit) => void;
}

// Component
export const VisitsTabs = ({ showVisitDetails }: VisitsTabsProps) => {
  // Data
  const { data: visitor } = useQueryData(ApiEndpoints.reservations.detail, ['Reservations', 'Details']);

  // Intl
  const intl = useIntl();

  // Data
  const futureVisits = visitor?.Visits.filter((visit) => !isInThePast(visit.ValidTo));

  // Default Tabs
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Flex padding={24}>
          <Skeleton active />
        </Flex>
      ),
    },
  ];

  const renderEmpty = () => [
    {
      key: '0',
      label: <Translated id="visits.info.visit" />,
      children: (
        <Flex padding={24} justifyContent="center">
          <Empty description={<Translated id="visits.info.noFutureVisitsFound" />} />
        </Flex>
      ),
    },
  ];

  // Visits Tabs
  const renderTabs = () => {
    if (!visitor) return renderLoading();
    if (isEmpty(futureVisits)) return renderEmpty();

    return futureVisits?.map((visit, index) => {
      const guest = visit.Guests.find((x) => x.Id === visitor.Id);
      const checkedInVisit = guest?.Status === GuestStatus.CheckedIn;
      const checkedOutClass = guest?.Status === GuestStatus.CheckedOut ? 'is-checked-out' : '';
      const className = checkedInVisit ? 'is-checked-in' : checkedOutClass;
      return {
        key: visit.Id,
        label: (
          <span className={className}>
            {checkedInVisit && <CheckCircleOutlined />}
            {visit.Title || `${intl.formatMessage({ id: 'visits.info.visit' })} ${index}`}
          </span>
        ),
        children: visitor && <VisitsTabContent visit={visit} visitor={visitor} showVisitDetails={showVisitDetails} />,
      };
    });
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderTabs()} tabBarExtraContent={<VisitsHistory visitor={visitor} />} />
    </StyledWidget>
  );
};
