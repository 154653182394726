import React, { useState } from 'react';
import { Button, Card, Col, Empty, Modal, Space } from 'antd';
import { isEmpty } from 'lodash';
import { HistoryOutlined } from '@ant-design/icons';

// Models
import { Visit } from '../../../../models/Visits/Visit';
import { Visitor } from '../../../../models/Visits/Visitor';

// Constants
import { isInThePast } from '../../../../constants/Utils/DateTime';

// Components
import { VisitDetails } from './VisitDetails';
import { Translated } from '../../../UI/Core';
import { ColorButton } from '../../../UI/Button/ColorButton';

// Props
interface VisitsHistoryProps {
  visitor?: Visitor;
}

// Component
export const VisitsHistory = ({ visitor }: VisitsHistoryProps) => {
  // State
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Event Handlers
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // Visits Cards
  const renderVisitCards = (visits?: Visit[]) => {
    if (isEmpty(visits))
      return (
        <Col xs={24}>
          <Empty description={<Translated id="visits.info.noPastVisitsFound" />} />
        </Col>
      );

    return visits?.map((visit, index) => (
      <Card
        key={visit.Id}
        title={visit.Title ?? visit.Description ?? visit.Id ?? index}
        size="small"
        className="gx-mb-0"
      >
        <VisitDetails visit={visit} />
      </Card>
    ));
  };

  // Component
  return (
    <>
      <ColorButton color="main" className="gx-mb-0 gx-mr-3" onClick={showModal} icon={<HistoryOutlined />} />

      <Modal
        title={<Translated id="visits.info.historicalVisits" />}
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <Button onClick={handleOk}>
            <Translated id="app.close" />
          </Button>
        }
      >
        <Space direction="vertical" size="middle" style={{ display: 'flex', maxHeight: 480, overflow: 'auto' }}>
          {renderVisitCards(visitor?.Visits.filter((visit) => isInThePast(visit.ValidTo)))}
        </Space>
      </Modal>
    </>
  );
};
