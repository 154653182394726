import React from 'react';
import { ColumnInstance } from 'react-table';
import styled, { css } from 'styled-components';

// Components
import { SELECTION_COLUMN } from './TableSelectionColumn';

// Props
export interface TableDataProps<T extends object> {
  children: React.ReactNode;
  className?: string;
  column?: ColumnInstance<T>;
  style?: React.CSSProperties;
}

const filterColumnProps = (column: any) => {
  const validProps = ['id', 'className', 'style', 'colSpan', 'rowSpan', 'onClick', 'onMouseEnter', 'data-test'];

  return Object.keys(column)
    .filter((key) => validProps.includes(key))
    .reduce((filteredProps: any, key: string) => {
      return {
        ...filteredProps,
        [key]: column[key],
      };
    }, {});
};

const GenericTableData = <T extends object>({
  children,
  className = undefined,
  column = undefined,
  style = undefined,
}: React.PropsWithChildren<TableDataProps<T>>) => {
  const validColumnProps = column ? filterColumnProps(column) : {};

  return (
    <td className={className} style={style} {...validColumnProps}>
      {children}
    </td>
  );
};

// Styled
export const TableData = styled(GenericTableData)`
  color: #616161;
  border-bottom: 1px solid #f2f2f2;
  height: 44px;
  line-height: 44px !important;
  padding: 0 8px !important;
  vertical-align: middle !important;

  &.no-border {
    border-bottom: none;
  }

  ${(props) =>
    props.column?.id === SELECTION_COLUMN &&
    css`
      width: 40px !important;
      flex: none !important;
    `};
`;
