import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Translated } from '../../UI/Core/Translated';
import { Flex } from '../../UI/Base';

// Props
interface AppMenuItemProps {
  id: string;
  to: string;
  icon?: string;
  IconComponent?: any;
}

export const AppMenuItem = ({ id, to, icon = undefined, IconComponent = undefined }: AppMenuItemProps) => (
  <Link to={to}>
    <Flex alignItems="center">
      {IconComponent ? <IconComponent /> : icon && <i className={`icon icon-${icon}`} />}
      <Translated id={`appMenu.${id}`} />
    </Flex>
  </Link>
);
