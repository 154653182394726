import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'antd';

// Components
import { useNavigate } from 'react-router';
import { Container } from '../../components/UI/Base';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer, ProfileHeader, VisitorIdentifiers } from '../../components/Reservations';
import { VisitsTabs } from '../../components/Reservations/Visits/VisitsTabs/VisitsTabs';
import { ReservationsDrawer } from '../../components/Reservations/VisitorReservations/ReservationsDrawer';
import { useQueryData } from '../../hooks/App/useQueryData';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { useVisitorDrawer } from '../../hooks/Reservations/useVisitorDrawer';
import { useVisitDrawer } from '../../hooks/Reservations/useVisitDrawer';
import { Visit } from '../../models/Visits/Visit';
import { useReservationsDrawer } from '../../hooks/Reservations/useReservationsDrawer';

export const ReservationsPage = () => {
  // Navigation Hook
  const navigate = useNavigate();

  // Data
  const {
    data: visitor,
    isPending: pendingVisitor,
    isFetched,
  } = useQueryData(ApiEndpoints.reservations.detail, ['Reservations', 'Details']);

  useEffect(() => {
    // Navigates to the index if not found
    if (!pendingVisitor && !visitor && isFetched) {
      navigate(`/reservations/new_visitor`);
    }
  }, [pendingVisitor, visitor, navigate, isFetched]);

  const { getFormDrawerProps: getVisitorFormDrawerProps, showDeleteConfirm } = useVisitorDrawer({ isEditing: true });
  const { getFormDrawerProps: getEditVisitFormDrawerProps, setVisit: setEditVisit } = useVisitDrawer({
    isEditing: true,
  });
  const { getFormDrawerProps: getReservationFormDrawerProps } = useReservationsDrawer();

  // Props
  const getProfileHeaderProps = useCallback(
    () => ({
      setEditVisitorOpen: getVisitorFormDrawerProps().setOpen,
      setReservationDrawerOpen: getReservationFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [getReservationFormDrawerProps, getVisitorFormDrawerProps, showDeleteConfirm]
  );

  const getVisitorVisitsProps = useCallback(
    () => ({
      showVisitDetails: (newVisit: Visit) => {
        setEditVisit(newVisit);
        getEditVisitFormDrawerProps().setOpen(true);
      },
    }),
    [getEditVisitFormDrawerProps, setEditVisit]
  );

  return (
    <Spinner spinning={pendingVisitor}>
      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={14} lg={14} md={14} sm={24} xs={24}>
            <Col>
              <VisitorIdentifiers />
            </Col>
          </Col>

          <Col xl={10} lg={10} md={10} sm={24} xs={24}>
            <Col>
              <VisitsTabs {...getVisitorVisitsProps()} />
            </Col>
          </Col>
        </Row>
      </Container>

      <ReservationsDrawer {...getReservationFormDrawerProps()} />
      <FormDrawer {...getVisitorFormDrawerProps()} />
      <FormDrawer {...getEditVisitFormDrawerProps()} />
    </Spinner>
  );
};
