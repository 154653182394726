import React from 'react';
import { Button, Modal } from 'antd';
import { Translated } from '../UI/Core';
import { Visitor } from '../../models/Visits/Visitor';

interface VisitsHistoryProps {
  visitor: Visitor | null;
  signedDocumentUrl: string | undefined;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SignedNDA = ({ visitor, signedDocumentUrl, isModalOpen, setIsModalOpen }: VisitsHistoryProps) => {
  const originalDocumentUrl = visitor?.Signatures?.[0]?.OriginalDocument?.Url;
  const signatureUrl = visitor?.Signatures?.[0]?.Signature?.Url;

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const downloadFile = (url: any, filename: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      title={<Translated id="visitor.signed.nda.title" />}
      centered
      bodyStyle={{ height: '80vh' }}
      width={1000}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={[
        signedDocumentUrl ? null : (
          <>
            <Button key="downloadOriginal" onClick={() => downloadFile(originalDocumentUrl, 'original-document.pdf')}>
              <Translated id="app.downloadOriginal" defaultMessage="Download Original PDF" />
            </Button>
            <Button key="downloadSigned" onClick={() => downloadFile(signatureUrl, 'signed-document.png')}>
              <Translated id="app.downloadSigned" defaultMessage="Download Signed Image" />
            </Button>
          </>
        ),
        <Button key="close" onClick={handleOk}>
          <Translated id="app.close" />
        </Button>,
      ]}
    >
      {signedDocumentUrl ? (
        <embed style={{ height: '100%', width: '100%' }} src={signedDocumentUrl} type="application/pdf" />
      ) : (
        <img src={originalDocumentUrl} alt="preview" style={{ height: '100%', width: '100%' }} />
      )}
    </Modal>
  );
};
