import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from '../../models/ApiResponse';
import { redirectToApiAuthorizationPath } from '../../constants/Utils/Urls';
import { ApplicationPaths } from '../../constants/Settings/AuthSettings';

export const useQueryData = <T, A extends any[]>(
  apiCallFn: (...parameters: A) => Promise<ApiResponse<T>>,
  queryKey: string[],
  parameters = [] as any as A
) => {
  const { data, isPending, isFetched } = useQuery({
    queryKey: [...queryKey, ...parameters],
    queryFn: async () => {
      try {
        const { Data } = await apiCallFn(...parameters);
        return Data;
      } catch (error: any) {
        if (error?.response?.status === 403) {
          redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
        }
        return undefined;
      }
    },
  });

  return { data, isPending, isFetched };
};
