import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars-2';

// Components

// *** TableWrapper ***
export const TableWrapper = styled.table`
  width: 100%;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
`;

// *** TableScrollbars***
export const TableScrollbars = styled(Scrollbars)`
  border: 1px solid #f2f2f2;
  border-radius: 6px;
`;
