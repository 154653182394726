import React from 'react';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useAppSelector } from '../../../hooks/App/useRedux';

// Constants
import { useMenuItems } from '../../../data/Menu/MenuItems';

export const AppMenu = () => {
  const navigate = useNavigate();

  const pathname = useAppSelector(({ appSettings }) => appSettings?.pathname ?? '/');
  const selectedKey = pathname === '/' ? 'Dashboard' : pathname.substring(1).split('/')[0];

  return (
    <Menu
      onClick={(e) => navigate(`/${e.key}`)}
      selectedKeys={[selectedKey]}
      mode="horizontal"
      items={useMenuItems()}
    />
  );
};

AppMenu.propTypes = {};
