/* eslint-disable no-nested-ternary */
import React from 'react';
import { Drawer, DrawerProps } from 'antd';
import styled from 'styled-components';

// Models
// Components
import { Translated } from '../../UI/Core';
import { Visit } from '../../../models/Visits/Visit';
import { FormDrawer } from '../../UI/Drawer/FormDrawer';
import { FormOptions } from '../../../types/Table';
import { ReservationsForm } from './ReservationsForm';

// Styled
const StyledDrawer = styled(Drawer)`
  & .ant-drawer-content-wrapper {
    max-width: 100%;
    width: 736px !important;

    @media (min-width: 800px) {
      width: 378px !important;
    }
  }
`;

// Props
interface FormDrawerProps<T extends object> extends FormOptions<T> {
  updating: boolean;
  open: boolean;
  size: DrawerProps['size'];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ReservationsDrawerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setVisit: React.Dispatch<React.SetStateAction<Visit | undefined>>;
  getCreateVisitFormDrawerProps: () => FormDrawerProps<Visit>;
}

// Component
export const ReservationsDrawer = ({
  open,
  setOpen,
  setVisit,
  getCreateVisitFormDrawerProps,
}: ReservationsDrawerProps) => {
  return (
    <StyledDrawer title={<Translated id="reservations.form.create" />} open={open} onClose={() => setOpen(false)}>
      <ReservationsForm setVisit={setVisit} setOpen={getCreateVisitFormDrawerProps().setOpen} />
      <FormDrawer {...getCreateVisitFormDrawerProps()} />
    </StyledDrawer>
  );
};
