import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useIntl } from 'react-intl';

// Components
import { Translated } from '../../../UI/Core';
import { FormColumn } from '../../../UI/Form/FormColumn';

// Props
export interface VisitFormGuestsFieldsProps {
  index: number;
  isReadOnly: boolean;
}

// Component
export const VisitFormGuestsFields = ({ index, isReadOnly }: VisitFormGuestsFieldsProps) => {
  // Intl
  const intl = useIntl();

  return (
    <>
      {/* First Name */}
      <Col>
        <Form.Item
          name={[index, 'FirstName']}
          label={<Translated id="visitors.firstName" />}
          rules={[{ required: true, message: intl.formatMessage({ id: 'visitors.form.warnings.firstName' }) }]}
        >
          <Input placeholder="Hubert" disabled={isReadOnly} />
        </Form.Item>
      </Col>

      {/* Prefix & Last Name */}
      <Row style={{ flexDirection: 'row' }}>
        <FormColumn padRightNone xs={7} md={7}>
          <Form.Item name={[index, 'Prefix']} label={<Translated id="visitors.prefix" />}>
            <Input placeholder="van der" disabled={isReadOnly} />
          </Form.Item>
        </FormColumn>
        <FormColumn xs={17} md={17}>
          <Form.Item
            name={[index, 'LastName']}
            label={<Translated id="visitors.lastName" />}
            rules={[{ required: true, message: intl.formatMessage({ id: 'visitors.form.warnings.lastName' }) }]}
          >
            <Input placeholder="Renner" disabled={isReadOnly} />
          </Form.Item>
        </FormColumn>
      </Row>

      {/* Email */}
      <Col>
        <Form.Item
          name={[index, 'Email']}
          label={<Translated id="visitors.email" />}
          rules={[
            { required: true, message: intl.formatMessage({ id: 'visitors.form.warnings.email' }) },
            {
              type: 'email',
              message: intl.formatMessage({ id: 'form.warnings.emailFormat' }),
            },
          ]}
        >
          <Input type="email" placeholder="h.renner@company.com" disabled={isReadOnly} />
        </Form.Item>
      </Col>

      {/* Phone Number */}
      <Col>
        <Form.Item
          name={[index, 'PhoneNumber']}
          label={<Translated id="visitors.phone" />}
          rules={[
            {
              pattern: /^[+]?[-\s0-9()]*$/,
              message: intl.formatMessage({ id: 'form.warnings.phoneNumberFormat' }),
            },
          ]}
        >
          <Input type="tel" placeholder="+31 6 3208 3380" disabled={isReadOnly} />
        </Form.Item>
      </Col>

      {/* Company Name */}
      <Col>
        <Form.Item name={[index, 'CompanyName']} label={<Translated id="visitors.companyName" />}>
          <Input placeholder="Bluefield Smart Access" disabled={isReadOnly} />
        </Form.Item>
      </Col>
    </>
  );
};
