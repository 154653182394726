/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Col, Empty, Row, Skeleton, Tabs } from 'antd';
import styled from 'styled-components';
import { isEmpty, orderBy } from 'lodash';

// Models
import { CheckCircleOutlined } from '@ant-design/icons';

// Hooks
// Components
import { Widget } from '../../UI/Widget/Widget';
import { Spinner } from '../../UI/Spinner/Spinner';
import { Translated } from '../../UI/Core';
import { AppColors } from '../../../constants/Utils/Colors';
import { VisitorIdentifierForm } from './VisitorIdentifierForm';
import { GuestStatus } from '../../../models/enums/GuestStatus';
import { ApiEndpoints } from '../../../data/ApiEndpoints';
import { useQueryData } from '../../../hooks/App/useQueryData';
import { isInThePast } from '../../../constants/Utils/DateTime';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }

  & .ant-tabs-nav {
    height: 58px;
  }

  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }

  & .ant-tabs-tab {
    & .ant-tabs-tab-btn {
      & .ant-badge {
        color: inherit;
      }

      & .is-checked-in {
        color: ${AppColors.orange};
        font-weight: 600;
      }
    }
  }
`;

// Component
export const VisitorIdentifiers = () => {
  const { data: identifierTypes, isPending: pendingIdentifierTypes } = useQueryData(
    ApiEndpoints.reservations.identifierTypes,
    ['Reservations', 'IdentifierTypes']
  );

  const { data: visitor, isPending: pendingVisitor } = useQueryData(ApiEndpoints.reservations.detail, [
    'Reservations',
    'Details',
  ]);

  const futureVisits = visitor?.Visits.filter((x) => !isInThePast(x.ValidTo));
  const hasVisits = !isEmpty(futureVisits);

  const loading = pendingVisitor || pendingIdentifierTypes || !visitor || !identifierTypes;
  const defaultActiveKey = loading || isEmpty(identifierTypes) ? '0' : identifierTypes[0].Id;

  const isCheckedIn = useMemo(() => {
    const guests = visitor?.Visits.map((visit) => visit.Guests.find((x) => x.Id === visitor.Id));
    return guests?.some((x) => x?.Status === GuestStatus.CheckedIn) ?? false;
  }, [visitor]);

  // Data
  const orderedTypes = orderBy(
    identifierTypes,
    (identifierType) => isCheckedIn && identifierType.DefaultForCheckIn,
    'desc'
  );

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderEmpty = () => [
    {
      key: '0',
      label: <Translated id="identifiers.form.tab" />,
      children: (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="identifiers.form.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = () =>
    orderedTypes.map((identifierType) => {
      const isIdentifierCheckedIn = identifierType.DefaultForCheckIn && isCheckedIn;
      const visitorIdentifier =
        visitor?.Identifiers.find((x) => x.IdentifierType.Id === identifierType.Id) ?? undefined;

      return {
        key: identifierType.Id,
        label: (
          <span className={isIdentifierCheckedIn ? 'is-checked-in' : undefined}>
            {isIdentifierCheckedIn && <CheckCircleOutlined />}
            {identifierType.Name}
          </span>
        ),
        children: (
          <Spinner spinning={loading}>
            <VisitorIdentifierForm
              visitor={visitor}
              identifier={visitorIdentifier}
              identifierType={identifierType}
              hasVisits={hasVisits}
              isCheckedIn={isCheckedIn}
            />
          </Spinner>
        ),
      };
    });

  const renderItems = () => {
    if (loading) return renderLoading();
    if (!identifierTypes || isEmpty(identifierTypes)) return renderEmpty();
    return renderForm();
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderItems()} defaultActiveKey={defaultActiveKey} />
    </StyledWidget>
  );
};
