import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DrawerProps } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { usePrevious } from 'react-use';

// Types
import { FormOptions } from '../../types/Table';

// Components
import { StyledTooltip } from '../../components/UI/Tooltip/StyledTooltip';
import { Translated } from '../../components/UI/Core';

// Styled
const StyledSpan = styled.span`
  width: 14px;
  height: 14px;
  line-height: 16px;
  display: flex;
`;
const StyledButton = styled(Button)`
  padding: 5px 10px;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  :focus,
  :active,
  :active:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

// Props
interface UseFormDrawerProps<T extends object> {
  formOptions: FormOptions<T>;
  size?: DrawerProps['size'];
  updating: boolean;
  error: boolean;
}

// Hook
export const useFormDrawer = <T extends object>({ formOptions, size, updating, error }: UseFormDrawerProps<T>) => {
  // Redux State
  const prevUpdating = usePrevious(updating);

  // Drawer State
  const [open, setOpen] = useState(false);

  // Form
  const { Form, endpoint, labels } = formOptions;
  const getFormDrawerProps = useCallback(
    () => ({
      Form,
      endpoint,
      labels,
      size,
      updating,
      open,
      setOpen,
    }),
    [Form, endpoint, labels, size, updating, open, setOpen]
  );

  // Components
  const AddButton = useCallback(
    () => (
      <StyledTooltip title={labels?.createButton ?? <Translated id="form.createButton" />} placement="bottomRight">
        <StyledButton onClick={() => setOpen(true)}>
          <StyledSpan>
            <PlusOutlined />
          </StyledSpan>
        </StyledButton>
      </StyledTooltip>
    ),
    [labels, setOpen]
  );

  // Effects
  useEffect(() => {
    // Close Drawer on successful update
    if (prevUpdating === true && !updating && !error) {
      setOpen(false);
    }
  }, [prevUpdating, updating, error]);

  return useMemo(
    () => ({
      AddButton,
      getFormDrawerProps,
    }),
    [AddButton, getFormDrawerProps]
  );
};
