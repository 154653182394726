import React from 'react';
import { Progress, ProgressProps, Tooltip } from 'antd';

// Models
import { ProgressTooltipProps } from '../../../models/UI/ProgressTooltipProps';
import { AppColors } from '../../../constants/Utils/Colors';

// Props
interface LineProgressProps extends ProgressProps, ProgressTooltipProps {
  empty?: boolean;
}

// Component
export const LineProgress = ({
  percent,
  success,
  tooltip,
  strokeColor,
  trailColor,
  empty = true,
}: LineProgressProps) => (
  <Tooltip title={tooltip.content} placement={tooltip.placement}>
    <Progress
      percent={percent}
      success={success}
      strokeColor={strokeColor}
      trailColor={!empty && trailColor ? trailColor : `${AppColors.sky}99`}
      showInfo={false}
      strokeWidth={10}
      status="normal"
      type="line"
    />
  </Tooltip>
);
